<template>
   <div class="staff-list-wrapper">
       <div class="flex-box-column staff-list-cover">
            <div class="hd">
                 <van-nav-bar left-arrow safe-area-inset-top :title="setStaffType+'导入列表'"  right-text="确认导入" class="search" @click-left="back" @click-right="confirmBatchStaffData">
                </van-nav-bar>
            </div>
            <div class="bd">
                <van-empty image="error" description="获取员工数据失败，请重新导入"  v-if="pageErr" />
                <template v-for="(item,index) in staffList">
                    <van-cell title-class="p-b-10" :key="index">
                        <template #icon>
                            <div class="avatar" :class="{man:item.sex==1 || !item.sex,woman:item.sex==2}"></div>
                        </template> 
                        <template #title>
                            <span>{{item.name}}</span>
                            <span class="phone">{{item.phone}}</span>
                        </template>  
                        <template #label>
                            <span>{{item.shopname}}</span>
                        </template> 
                        <template>
                            <div class="delete-btn" @click.stop="removeBatchStaff(item,index)">
                                <van-icon name="delete-o" />
                            </div>
                        </template>
                    </van-cell>
                </template>
                <van-empty description="没有员工信息" v-if="staffList.length == 0 && !pageErr"/>
            </div>
       </div>
  
   </div>
</template>

<script>
import backMixin from '@/mixins/back';
import permissionCheckMixin from "@/mixins/permissionCheck";
import api from "@/api";
export default {

    mixins:[backMixin,permissionCheckMixin],


    data(){

        return {

            //搜索关键字

            keyword:'',

            //员工列表

            staffList:[],



            pageErr:false

        }

    },

    computed:{


        //是否为开发商总账号

        isDistributorAdmin(){
            return this.$store.state.userInfo.distributorStaffType == 'DIANZHANG' ? this.$store.state.userInfo.distributorControl ? '管理员' : '店长(经理)' : '店长(经理)';
        },

        setStaffType(){
            return this.$store.state.userInfo.role == 'DIANZHANG' ? this.$store.state.userInfo.distributorControl ? '店长(经理)' : '业务员' : '业务员';
        }

    },


    methods:{


        init(){

            if(this.$route.params.list){

                let msg = this.$route.params.list?.msg;

                if(msg && msg !='ok'){

                    msg = msg.replace(/\\n/g,'\n');

                    this.$dialog.alert({

                        title:'提示',

                        message:msg,

                        confirmButtonColor:"#00a0e4"

                    }).then(()=>{});

                }

                this.staffList = this.$route.params.list.data;

            }else{

                this.pageErr = true;

            }

        },


        //删除用户

        removeBatchStaff(item,index){
            
            this.$dialog.confirm({

                title:`删除${this.setStaffType}`,

                message:`确定要删除 ${item.name} 吗？`,

                confirmButtonColor:"#00a0e4"

            }).then(()=>{

                 this.staffList.splice(index,1);

            }).catch(()=>{})

        },

        //导入数据

        confirmBatchStaffData(){

            if(this.staffList.length == 0){

                this.$toast('没有需要导入的数据');

                return;

            }


            this.$dialog.confirm({

                title:'确认导入',

                message:`确认要导入这些${this.setStaffType}吗？共${this.staffList.length}人`,

                confirmButtonColor:"#00a0e4"

            }).then(()=>{

                this.$toast.loading({

                    message:'正在导入...',

                    duration:0

                })

                api.batchAddStaff({

                    options:{

                        list:this.staffList

                    },

                    success: (res)=> {

                        res.msg = res.msg.replace(/\\n/g,'\n');

                        if(res.type == 200){

                            this.$dialog.alert({

                                title:'导入成功',

                                message:`${res.msg ? res.msg+'，' : ''}已成功导入${res.data.num}个${this.setStaffType},导入账号的登录密码默认为注册手机号码后六位，登录后请立即修改密码`,

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{

                                this.$store.commit('setRouterData',{key:'isStaffListRefresh',value:true});

                                this.$nextTick(()=>{

                                    this.$router.back();

                                })

                            })

                        }else{

                            this.$dialog.alert({

                                title:'导入失败',

                                message:res.msg || '导入失败，请稍后重试'

                            }).then(()=>{})

                        }

                        
                        
                    },

                    error:(err)=>{

                        this.$dialog.alert({

                            title:'导入失败',

                            message:'导入失败，请稍后重试，'+err

                        }).then(()=>{})

                    },

                    complete: ()=> {

                        this.$toast.clear();
                        
                    }

                })

            }).catch(()=>{})


        }

    },

    created(){

        this.$nextTick(()=>{

            //检查一下使用权限

            let check = this.permissionCheck();

            if(check){

                this.init();

            }


        })

    }

}
</script>

<style lang="less">

 .staff-list-wrapper{

     height:100%;

     .delete-btn{

         padding: 0 15px;

         .van-icon{

            font-size:20px;

         }

     }

        .tips{

            padding: 10px 15px;

            font-size:12px;

            color:#999;

            line-height:20px;

            &.p-top-none{

                padding-top:0;

            }
            
            &.p-bottom-none{

                padding-bottom:0;

            }

            &.p-right-none{

                padding-right:0;

            }
            
            &.p-left-none{

                padding-left:0;

            }

            &.btn{

                &:active{

                    opacity: .8;

                } 

            }

        }

        .record-change-dialog{

            .van-dialog__content{

                max-width: 70vh;

                overflow: hidden;

                overflow-y:auto;

            }

        }

        .remove-button{

            text-align: center;

            padding: 10px 0 20px;

            font-size:12px;

            span{

                display: inline-block;

                padding: 5px 10px;

                border-radius:4px;

                line-height:20px;

                .van-icon{

                    vertical-align: middle;

                    padding-right:5px;

                    font-size:14px;

                    margin-top:-2px;

                }

            }

        }

        .remove-tips{

            padding: 15px;

            font-size:14px;

            color:#666666;

            line-height:24px;

        }

    .staff-list-cover{

        height:100%;

   

        .search-cover{

            width: 65%;

            margin: 0 auto;

 

        }



        .bd{

            flex:1;

            height:0;

            position: relative;

            overflow: hidden;

            overflow-y:auto;

            background: #f6f6f6;

            padding-bottom:50px;

            .van-empty{

                background: #fff;

            }

            .van-button{

                height:100%;

            }

            .van-index-anchor{

                padding: 0 30px;

            }

            .van-swipe-cell__wrapper{

                background: #fff;

                .tel{

                    width: 60px;

                    display: inline-flex;

                    align-items: center;

                    justify-content: center;

                    background: #09dd70;

                    color:#fff;

                    height:100%;

                    vertical-align: top;

                    font-size:15px;

                    line-height: 1.2;

                }

            }


            .van-cell{

                align-items: center;

                padding: 5px 0 5px 16px;

                .phone{

                    font-size:12px;
                    padding-left:5px;

                    color:#999;

                }

                &:after{

                    right:0;

                    left:55px;

                }

                .avatar{

                    width: 38px;

                    height:38px;

                }

                .van-cell__title{

                    padding-left:15px;

                    padding-bottom:0;

                }

            }
               
        }
        

    }

    .batch-add-dialog-cover{

        .paste-cover{

            width: 100%;

            border-radius: 4px;

            overflow: hidden;

            padding: 15px 10px;

            textarea{

                width: 100%;

                border-radius: 4px;

                           height:200px;

                resize:none;

                padding: 10px;

                border:1px solid #eee;

                overflow: hidden;

                overflow-y:auto;

                background: #f8f8f8;

                color:#666666;

                transition:border-color .5s;

                &:focus{

                    border-color:#5aceff;

                }

            }

    }

    }
    

 }

</style>
